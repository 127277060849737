





































































































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import BeatUploadedFiles from '#/components/BeatUploadedFiles.vue'
import BeatInfoDropdown from '#/components/BeatInfoDropdown.vue'

import { UploadStore, UploadType } from '~/store/upload'
import { fetchSingleBeat, updateBeat } from '#/api/beat'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UserStore } from '#/store/user'
import { fetchCoproducerDataOfBeat, removeCoproducerOfBeat, createCoproducerOnBeat } from '#/api/co-producer'
import { fetchSingleBeatPlays } from '#/api/analytics'
import { fetchLicenseOptions } from '#/api/license-option'

@Component({ components: { VLoadSpinner, BeatUploadedFiles, BeatInfoDropdown } })
export default class BeatEditPage extends Vue {
	@Prop({ type: Number }) id!: number

	regexUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
	UploadType = UploadType
	beat: IBeatProducer = null
	beatPlays: number = 0
	isLoading = true
	coproducersDropdown = false
	commissionPercentageDropdown = false
	coproducerData: Array<Partial<ICoProducer>> = []
	licenseOptions: Array<Partial<ILicenseOptionProducer>> = []

	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

	get coproducerEmails(): IUserProducer['coproducers'] {
		return this.authenticatedUser ? this.authenticatedUser.coproducers : []
	}

	get hasExclusiveLicense() {
		return this.licenseOptions.some(item => item.is_exclusive)
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
	@UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

	// needed for emit of BeatInfoDropdown
	setArtistType(artistType: IArtistType) {
		this.beat.artist_type = artistType
	}
	// needed for emit of BeatInfoDropdown
	setGenre(genre: IGenre) {
		this.beat.genre = genre
	}
	// needed for emit of BeatInfoDropdown
	setMainInstrument(instrument: IInstrument) {
		this.beat.main_instrument = instrument
	}

	async fetchSingleBeat() {
		this.beat = await fetchSingleBeat(this.id)
		this.beatPlays = await fetchSingleBeatPlays(this.id)
		this.isLoading = false
	}

	async uploadBeatImage(fileList: FileList) {
		try {
			const { url } = await this.singleFileUpload({ fileList, uploadType: UploadType.Public, maxPxSize: 500 })
			await updateBeat({ id: this.beat.id, image_url: url })
			this.beat.image_url = url
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	async removeBeatImage() {
		await updateBeat({ id: this.beat.id, image_url: '' })
		this.beat.image_url = ''
	}

	closeDropdowns() {
		this.coproducersDropdown = false
		this.commissionPercentageDropdown = false
	}

	async fetchCoproducerDataOfBeat() {
		// should return array of co-producers or empty array
		this.coproducerData = await fetchCoproducerDataOfBeat(this.id)
	}

	setCoproducerData({ atIndex, email, commission }: any) {
		if (email) this.coproducerData[atIndex].coproducer = email
		else this.coproducerData[atIndex].commission = commission
		this.closeDropdowns()
	}

	async updateBeatCoproducerData() {
		// delete old coproducers
		this.coproducerData.forEach(item => {
			if (item.id) removeCoproducerOfBeat(item.id)
			// post new ones
			createCoproducerOnBeat({ ...item, beat: this.id })
		})
	}

	// updates beat and co-producers data
	async saveAllChanges() {
		// remove properties that can't be changed from this page
		const { tagged_file_name_thecharts, mp3_file_name, wav_file_name, trackout_file_name, ...beatCleaned } = this.beat
		await updateBeat(beatCleaned)
		this.updateBeatCoproducerData()
		this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
	}

	addNewCoproducer() {
		if (!this.coproducerData) this.coproducerData = []
		this.coproducerData.push({ coproducer: null, commission: 0 })
	}

	deleteCoproducerAtIndex(atIndex: number) {
		this.coproducerData.splice(atIndex, 1)
	}

	async mounted() {
		this.licenseOptions = await fetchLicenseOptions()
		this.fetchSingleBeat()
		this.fetchCoproducerDataOfBeat()
		// TODO: why is it here??? comment out for now
		// this.$store.commit('beat/SET_OR_RESET_SELECTED_BEATS', this.beat)
	}
}
